$breakpoint-lg: 768px;

body {
  background-color: #232627;
  margin: 0;
  height: 100vh;

  .main {
    padding: 2rem 0 0;
    @media (min-width: $breakpoint-lg) {
      padding-top: 20vh;
    }

    @media (min-width: 576px) {
      padding: 20vh 0 0 0;
    }

    width: 100%;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    a {
      color: unset;
    }

    .intro {
      margin: 0 0 4rem 0;
      color: #11D116;
      font-family: 'Roboto Mono', monospace;
      font-size: 3rem;

      .character {
        display: none;
      }

      .cursor {
        transition: opacity .15s;
      }
    }

    .opacity-animation {
      opacity: 0;
      transition: opacity .5s;
    }

    .secondary-text {
      color: #b3b4b4;
      font-family: 'Open Sans', sans-serif;
    }

    .currently-at {
      font-size: 1.33rem;
      margin: 0 0 3rem 0;
    }

    .contacts {
      font-size: 1rem;

      .contact-logos {
        list-style: none;
        padding: 0;

        li {
          display: inline;

          &:not(:first-child) {
            margin-left: 0.5rem;
          }

          a.contact-logo {
            text-decoration: none;
          }
        }
      }
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    word-wrap: normal;
    border: 0;
  }
}
